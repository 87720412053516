/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';

// images
import bgFront from 'assets/img/rotating-card-bg-front.jpg';
import bgBack from 'assets/img/rotating-card-bg-back.jpg';

// MUI
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });

// MUI Icons
const Api = dynamic(() => import('@mui/icons-material/Api'), { loading: () => null });
const DeveloperBoard = dynamic(() => import('@mui/icons-material/DeveloperBoard'), { loading: () => null });
const Monitor = dynamic(() => import('@mui/icons-material/Monitor'), { loading: () => null });
const Storage = dynamic(() => import('@mui/icons-material/Storage'), { loading: () => null });
const TouchApp = dynamic(() => import('@mui/icons-material/TouchApp'), { loading: () => null });

// Parts
const RotatingCard = dynamic(() => import('examples/RotatingCard'), { loading: () => null });
const RotatingCardFront = dynamic(() => import('examples/RotatingCard/RotatingCardFront'), { loading: () => null });
const RotatingCardBack = dynamic(() => import('examples/RotatingCard/RotatingCardBack'), { loading: () => null });
const DefaultInfoCard = dynamic(() => import('examples/DefaultInfoCard'), { loading: () => null });

function Skills() {
  return (
    <Container>
      <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: 'auto' }}>
        <Grid item xs={12} lg={4} sx={{ mx: 'auto' }}>
          <RotatingCard>
            <RotatingCardFront
              color="dark"
              image={bgFront.src}
              icon={<TouchApp />}
              title={(
                <>
                  Full Stack
                  <br />
                  Developer
                </>
              )}
              description="Looking to start a project or take one to production?"
            />
            <RotatingCardBack
              color="dark"
              image={bgBack.src}
              title="Interested?"
              description="If you'd like me to work on your project or join your team, send me a message."
              action={{
                type: 'internal',
                route: '/#contact',
                label: 'Send me a message',
              }}
            />
          </RotatingCard>
        </Grid>
        <Grid item xs={12} lg={7} sx={{ ml: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<Monitor color="info" />}
                title="Front End"
                description="React, Next, Vue, Typescript, Javascript, TailWind CSS, Material UI, Web3, JSX, JSS, HTML, SCSS, CSS"
              /> 
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<DeveloperBoard color="success" />}
                title="Back End"
                description="Postgres, Prisma, Express, Node, Socket.io, MongoDB, MySQL, MSSQL, Ethereum, Polygon, Solidity, PHP"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<Api color="warning" />}
                title="APIs"
                description="RESTful APIs, AWS, Stripe, PayPal, Authorize.net, MailChimp, CoinGecko"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DefaultInfoCard
                icon={<Storage color="error" />}
                title="Server"
                description="Serverless, Ubuntu, Debian, CentOS, NGINX, Apache"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Skills;
